import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png'; // Import your logo image
import loginSplash from './ggclogin.jpg'; // Import the login splash image

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [devBypass, setDevBypass] = useState(false);
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);

  const handleLogin = async () => {
    window.location = ('https://cateralchemy.com/ca-dashboard/');

    // try {
    //   const response = await fetch('http://127.0.0.1:8080/api/login_ca', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       password,
    //       username,
          
    //     }),
    //   });

    //   if (response.ok) {
    //     navigate('https://www.cateralchemy.com/ca-dashboard/');
    //   } else {
    //     setIncorrectAttempts(incorrectAttempts + 1);
    //     // Check for three incorrect attempts
    //     if (incorrectAttempts >= 2) {
    //       alert('You have been locked out.');
    //       return;
    //     }
    //     // Show toast or alert for incorrect credentials
    //     window.location = ('https://www.cateralchemy.com/ca-dashboard/');
    //    // alert('Incorrect username or password.');
    //   }
    // } catch (error) {
    //   console.error('Error logging in:', error);
    //   // Handle error
    // }
  };

  const handleResetPassword = () => {
    // Redirect to ResetPass component
    navigate('/reset-password');
  };

  return (
    <Grid container spacing={0} style={{ height: '100vh' }}>
      {/* Left side with image */}
      <Grid item xs={12} sm={6} style={{ overflow: 'hidden' }}>
        <img src={loginSplash} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      {/* Right side with login form */}
      <Grid item xs={12} sm={6} component={Paper} elevation={6} square>
        <div style={{ margin: '64px 32px', textAlign: 'center' }}>
          {/* Header with logo and text */}
          <img src={logo} alt="Logo" style={{ width: '80px', marginBottom: '16px' }} />
          <Typography variant="h4" gutterBottom style={{ fontFamily: 'cursive', marginBottom: '32px' }}>
            Global Gourmet Catering
          </Typography>
          {/* Login form */}
          <TextField
            label="Username"
            variant="outlined"
            margin="normal"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} color="primary" />}
            label="Remember me"
            style={{ marginTop: '16px' }}
          />
          <Typography variant="body2" style={{ marginTop: '16px', cursor: 'pointer' }} onClick={handleResetPassword}>
            Reset password
          </Typography>
          {/* <FormControlLabel
            control={<Checkbox checked={devBypass} onChange={(e) => setDevBypass(e.target.checked)} color="primary" />}
            label="DEV BYPASS LOGIN"
            style={{ marginTop: '16px' }}
          /> */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '32px' }}
            onClick={handleLogin}
          >
            Log In
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
